<template>
  <div id="browser">
    <div class="content" style="background-color: white;">
      <div class="mac" @click="download_win64">
        <img :src="load_png" alt="" />
        <span style="color: green;">Win64（推荐）</span>
      </div>
      <div class="windows" @click="download_win32">
        <img :src="load_png" alt="" />
        <span>Win32</span>
      </div>
    </div>
  </div>
</template>

<script>
import load_png from "@/assets/image/download/client_download.png";
export default {
  data() {
    return {
      load_png,
    };
  },

  methods: {
    download_win64() {
      window.open("https://www.xiaoguobx.com/Client_win64.exe");
    },
    download_win32() {
      window.open("https://www.xiaoguobx.com/Client_win32.exe");
    },
  },
};
</script>

<style lang="stylus" scoped>
#browser
  .content
    box-sizing border-box
    padding 200px 200px
    margin-top 77px
    height 800px
    display flex
    flex-flow row nowrap
    justify-content center
    background #f7f7f7
    .mac,.windows
      margin 0 10%
      border-radius 10px
      display flex
      flex-flow column nowrap
      justify-content center
      align-items center
      font-weight 700
      color #606266
      cursor pointer
      transition all .5s ease
      &:hover
        box-shadow 0px 4px 12px 0px rgba(20, 114, 255, 0.1);
        background white
        position relative
        top 10px
</style>
